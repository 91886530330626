var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "12" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "components.OrderDetailEventsPart.title",
                    expression: "'components.OrderDetailEventsPart.title'"
                  }
                ]
              }),
              _c("CCardBody", [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "components.OrderDetailEventsPart.date",
                            expression:
                              "'components.OrderDetailEventsPart.date'"
                          }
                        ],
                        attrs: { scope: "col" }
                      }),
                      _c("th", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "components.OrderDetailEventsPart.event",
                            expression:
                              "'components.OrderDetailEventsPart.event'"
                          }
                        ],
                        attrs: { scope: "col" }
                      })
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.events, function(event) {
                      return _c("tr", { key: event.id }, [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.formatFullDate(event.date)) +
                              " (" +
                              _vm._s(_vm.formatDistance(event.date)) +
                              ")"
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.OrderDetailEventsPart.events." +
                                    event.type,
                                  { percent: event.cancellationPercentage }
                                )
                              ) +
                              " "
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }